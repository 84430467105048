import React , {useContext} from 'react';
import { Grid, CssBaseline, Container } from "@material-ui/core"
import { red, green } from "@material-ui/core/colors"
import { FiLinkedin, FiTwitter, FiRss, FiHeart } from "react-icons/fi"
import { Link as GatsbyLink, StaticQuery, graphql } from "gatsby"
import { Link } from "@material-ui/core"
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import DarkToggle from './common/DarkToggle'
import {withPlugin} from 'react-tinacms';
import {createRemarkButton} from 'gatsby-tinacms-remark';
import slugify from 'slugify';
import ThemeContext from "../context/ThemeContext"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import LogoSVG from "../images/logo.svg"
import LogoDarkSVG from "../images/logo-dark.svg"

import "simplebar/dist/simplebar.min.css"
import "../styles/app.scss"

const Layout = ({children}) => {
  const {darkMode} = useContext(ThemeContext)
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: darkMode ? `dark` : `light`,
          background: {
            default: darkMode ? `#181818` : `#fff`,
          },
          primary: darkMode ? green : red,
          secondary: !darkMode ? green : red,
        },
        typography: {
          fontFamily: `'Roboto Mono', monospace;`,
          h1: { fontFamily: `'Montserrat', sans-serif`, fontWeight: 800 },
          h2: { fontFamily: `'Montserrat', sans-serif`, fontWeight: 800 },
          h3: { fontFamily: `'Montserrat', sans-serif`, fontWeight: 800 },
          h4: { fontFamily: `'Montserrat', sans-serif`, fontWeight: 800 },
          h5: { fontFamily: `'Montserrat', sans-serif`, fontWeight: 800 },
          h6: { fontFamily: `'Montserrat', sans-serif`, fontWeight: 800 },
        },
      }),
    [darkMode]
  )
  const isHome = true
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div
        className="viewport"
        style={{
          backgroundColor: theme.palette.background.default,
          transition: `background-color 0.5s`,
        }}
      >
        <div className="viewport-top">
          <header>
            <Grid container justify="space-between" className="site-head">
              <Grid item>
                {!isHome && (
                  <Link component={GatsbyLink} to="/">
                    <LogoDarkSVG className="site-logo" />
                  </Link>
                )}
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <OutboundLink
                      href="https://twitter.com/alejandrom2soy"
                      className="site-nav-item-teal"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FiTwitter />
                    </OutboundLink>
                  </Grid>
                  <Grid item>
                    <OutboundLink
                      href="https://www.linkedin.com/in/alejandromartinezaem/"
                      className="site-nav-item-blue"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FiLinkedin />
                    </OutboundLink>
                  </Grid>
                  <Grid item>
                    <OutboundLink
                      className="site-nav-item-green"
                      href={`https://feedly.com/i/subscription/feed/alejandroMaritnez/rss/`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FiRss />
                    </OutboundLink>
                  </Grid>
                  <Grid item>
                    <DarkToggle />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </header>
          <main className="site-main">{children}</main>
          <footer className="site-footer">
            <Grid
              container
              justify="space-around"
              alignItems="center"
              style={{ textAlign: "center" }}
            >
              <Grid item xs={12} md={2}>
                {darkMode ? (
                  <LogoDarkSVG className="site-logo" />
                ) : (
                  <LogoSVG className="site-logo" />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <p>
                  © {new Date().getFullYear()} Made with{" "}
                  <FiHeart color="red"></FiHeart> by Alejandro Martinez.
                </p>
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid container justify="center" alignItems="center">
                  <Grid item>
                    <OutboundLink
                      href="https://twitter.com/alejandrom2soy"
                      className="site-nav-item-teal"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FiTwitter />
                    </OutboundLink>
                  </Grid>
                  <Grid item>
                    <OutboundLink
                      href="https://www.linkedin.com/in/alejandromartinezaem/"
                      className="site-nav-item-blue"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FiLinkedin />
                    </OutboundLink>
                  </Grid>
                  <Grid item>
                    <OutboundLink
                      className="site-nav-item-green"
                      href={`https://feedly.com/i/subscription/feed/alejandroMaritnez/rss/`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FiRss />
                    </OutboundLink>
                  </Grid>
                  <Grid item>
                    <DarkToggle />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </footer>
        </div>
      </div>
    </ThemeProvider>
  )
};

export default Layout
