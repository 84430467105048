import React, { useState, useEffect, useContext } from "react"
import Lottie from "react-lottie"
import sunMoonData from "./sunmoon.json"
import ThemeContext from '../../context/ThemeContext'

import { IconButton, Tooltip } from "@material-ui/core"
const DarkToggle = () => {
    const {darkMode, setDarkMode} = useContext(ThemeContext)
    const [lottieState, setLottieState] = useState({
        isStopped: true,
        isPaused: false,
        speed: 1,
        direction: 1,
    })
    const defaultOptions = { loop: false,autoplay: false,animationData: sunMoonData }
    useEffect(() => {
        setLottieState((prev) => {
            return {
                ...prev,
                direction: lottieState.direction * -1,
                isStopped: false,
            }
        })
    },[darkMode])

    return (
        <Tooltip title={darkMode ? `Toggle Light Mode` : `Toggle Dark Mode`}>
            <IconButton
                className={`dark-toggle site-nav-item-${
                    darkMode ? `yellow` : `orange`
                }`}
                onClick={() => setDarkMode(prev => !prev)}
            >
                <Lottie
                    options={defaultOptions}
                    height={40}
                    width={40}
                    className="sunmoon"
                    isStopped={lottieState.isStopped}
                    isPaused={lottieState.isPaused}
                    speed={lottieState.speed}
                    direction={lottieState.direction}
                />
            </IconButton>
        </Tooltip>
    )
}
export default DarkToggle