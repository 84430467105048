/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useGlobalJsonForm } from "gatsby-tinacms-json"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ description, lang, meta, title }) => {
  const { site } = useStaticQuery(graphql`
    {
      site: dataJson(fileRelativePath: { regex: "/site/" }) {
        siteTitle
        shortTitle
        siteTitleAlt
        siteDescription
        siteUrl
        googleAnalyticsID
        social {
          twitter
          email
          github
        }
        fileRelativePath
        rawJson
      }
    }
  `)
  const [
    {
      siteTitle,
      shortTitle,
      siteTitleAlt,
      siteDescription,
      siteUrl,
      googleAnalyticsID,
      social,
    },
  ] = useGlobalJsonForm(site, {
    label: "SEO Settings",
    fields: [
      {
        name: "rawJson.siteTitle",
        label: "Site Title",
        component: "text",
      },
      {
        name: "rawJson.shortTitle",
        label: "Short Site Name",
        component: "text",
      },
      {
        name: "rawJson.siteTitleAlt",
        label: "Alt Site Title",
        component: "text",
      },
      {
        name: "rawJson.siteDescription",
        label: "Site Description",
        component: "textarea",
      },
      {
        name: "rawJson.siteUrl",
        label: "Site URL",
        component: "text",
      },
      {
        label: "Contact Info",
        name: "rawJson.social",
        description: "Contact info",
        component: "group",
        fields: [
          {
            label: "Email",
            name: "email",
            description: "Contact email",
            component: "text",
          },
          {
            label: "Twitter",
            name: "twitter",
            description: "Twitter handle",
            component: "text",
          },
          {
            label: "Github",
            name: "github",
            description: "Github username",
            component: "text",
          },
        ],
      },
      {
        name: "rawJson.googleAnalyticsID",
        label: "Google Analytics ID",
        component: "text",
      },
    ],
  })
  const metaDescription = description || siteDescription

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      defaultTitle={siteTitle}
      titleTemplate={`%s | ${siteTitle}`}
      meta={[
        ...meta,
        {
          name: `description`,
          content: siteDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: social.twitter,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string.isRequired,
// }

export default SEO
